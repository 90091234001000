@import "../../assets/styles/variables.scss";

.roxus-sidebar {
    min-height: calc(100vh - 64px);
}

.main-content {
    width: 100%;
    margin: 32px 64px;
}
