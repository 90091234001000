@import "../../assets/styles/variables.scss";

.security-tab {
    width: 100%;
    
    .ant-card {
        border-radius: $border-radius-md;
        .ant-card-head-title {
            @extend %gradient-text;
            font-size: $font-lg;
        }
        .ant-form-item-label > label {
            font-size: $font-md;
        }
        .submit-button {
            width: 100%;
            text-align: center;
        }
    }
}