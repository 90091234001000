@import '../../assets/styles/variables.scss';

.signin-form {
    height: 100%;
    .introduction {
        text-align: center;
        .intro-img {
            margin-bottom: $padding-sm;
            img {
                width: 100px;
            }
        }
        .intro {
            @extend %gradient-text;
            font-size: $font-lg;
            font-weight: 500;
        }
        margin-bottom: $padding-lg;
    }

    .form-outline {
        padding: 50px 50px;

        .login-form {
            min-width: 600px;

            input {
                line-height: 40px;
                padding: 0px 10px;
            }
    
            .login-form-forgot {
                float: right;
            }
    
            .login-form-button {
                width: 100%;
            }

            .register-now {
                cursor: pointer;
                color: #1890ff;
            }
        }
    }
    
}