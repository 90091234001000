@import '../../assets/styles/variables.scss';

.activate-modal {
    span {
        font-size: $font-md;
    }

    .ant-modal-content {
        border-radius: $border-radius-md;
    }

    .activate-title {
        @extend %gradient-text;
        font-size: $font-xxl;
        font-weight: 500;
    }

    .activate-email {
        font-weight: 500;
        color: $navy;
    }

    .activate-image {
        width: 250px;
    }
}