@import "../../assets/styles/variables.scss";

.activation-tab {
    .ant-card {
        border-radius: $border-radius-md;
        .ant-card-head-title {
            @extend %gradient-text;
            font-size: $font-lg;
        }

        .react-code-input {
            input {
                font-size: 28px !important;
            }
        }

        .submit-button {
            width: 100%;
            text-align: center;
        }
    }
}
