@import "../../assets/styles/variables.scss";

.recording-list-page {
    .recording-title {
        margin-bottom: $padding-md;
        .text {
            font-weight: 500;
            font-size: $font-xl;
            color: $navy;
        }
    }
}
