@import "../../assets/styles/variables.scss";

.activate-page {
    padding: 64px 64px;
    min-height: calc(100vh - 64px);

    .content {
        width: 100%;
        height: 100%;
    }

    span {
        font-size: $font-md;
    }

    .activate-status {
        @extend %gradient-text;
        font-size: $font-xxl;
        font-weight: 500;
    }
}
