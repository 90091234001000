@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{padding:0;margin:0}html,body{height:100%}.mt-md{margin-top:16px}.mt-sm{margin-top:12px}

.login-background{height:100%;background-size:cover;background:url(/static/media/login-background.05dc27a2.jpg);background-repeat:no-repeat;background-position:center}

.button-gra{min-width:200px;border:none;outline:none;display:inline-block;border-radius:99px;color:white;font-weight:bold;position:relative;z-index:1;padding:13px 30px;text-transform:uppercase;font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;letter-spacing:2px;cursor:pointer}.button-gra:before,.button-gra:after{content:" ";position:absolute;border-radius:99px}.button-gra:before{top:0;left:0;bottom:0;right:0;z-index:-2;background:linear-gradient(to right, #fc9107 0%, #fa106c 100%)}.button-gra:after{top:2px;bottom:2px;left:2px;right:2px;background-color:#43464a;z-index:-1;opacity:1;transition:all 0.6s ease-in-out}.button-gra:hover:after{opacity:0}.button-gra-inv{min-width:200px;border:none;outline:none;display:inline-block;border-radius:99px;color:#43464a;font-weight:bold;position:relative;z-index:1;padding:13px 30px;text-transform:uppercase;font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;letter-spacing:2px;cursor:pointer}.button-gra-inv:before,.button-gra-inv:after{content:" ";position:absolute;border-radius:99px}.button-gra-inv:before{top:0;left:0;bottom:0;right:0;z-index:-2;background:linear-gradient(to right, #fc9107 0%, #fa106c 100%)}.button-gra-inv:after{top:2px;bottom:2px;left:2px;right:2px;background-color:white;z-index:-1;opacity:1;transition:all 0.6s ease-in-out}.button-gra-inv:hover:after{opacity:0}

.signin-form .introduction .intro{background:linear-gradient(to right, #ff9009 0%, #fb126b 100%);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent}.signin-form{height:100%}.signin-form .introduction{text-align:center;margin-bottom:32px}.signin-form .introduction .intro-img{margin-bottom:12px}.signin-form .introduction .intro-img img{width:100px}.signin-form .introduction .intro{font-size:20px;font-weight:500}.signin-form .form-outline{padding:50px 50px}.signin-form .form-outline .login-form{min-width:600px}.signin-form .form-outline .login-form input{line-height:40px;padding:0px 10px}.signin-form .form-outline .login-form .login-form-forgot{float:right}.signin-form .form-outline .login-form .login-form-button{width:100%}.signin-form .form-outline .login-form .register-now{cursor:pointer;color:#1890ff}

.signup-modal .ant-modal-title{background:linear-gradient(to right, #ff9009 0%, #fb126b 100%);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent}.signup-modal .ant-modal-title{color:#fb106c;font-size:20px}

.activate-modal .activate-title{background:linear-gradient(to right, #ff9009 0%, #fb126b 100%);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent}.activate-modal span{font-size:16px}.activate-modal .ant-modal-content{border-radius:20px}.activate-modal .activate-title{font-size:32px;font-weight:500}.activate-modal .activate-email{font-weight:500;color:#282634}.activate-modal .activate-image{width:250px}

.login-page{height:100vh}

.account-profile{padding:12px}.account-profile .profile-content{padding:16px 12px;background:#f4f5f7;border-radius:10px}.account-profile .profile-content .avatar{cursor:pointer}.account-profile .profile-content .full-name{font-size:16px;font-weight:500;color:#282634}


.ant-popover-inner{border-radius:10px}.ant-popover-inner-content{padding:20px 30px}.ant-layout-header{background:#282634}.ant-layout-header .logo{float:left;width:120px;cursor:pointer}.ant-layout-header .right-header{display:flex;float:right}.ant-layout-header .right-header .notification{margin-right:32px}.ant-layout-header .right-header .notification .ant-badge-count{background:#fb106c;border-radius:10px;box-shadow:0 0 0 1px #fb106c}.ant-layout-header .right-header .notification .anticon-bell{cursor:pointer;font-size:20px;color:white}.ant-layout-header .right-header .avatar{cursor:pointer}

.account-popover .full-name{font-size:16px;font-weight:500;color:#282634}.account-popover .title{font-weight:400}.account-popover .ant-divider-horizontal{margin:12px 0}.account-popover .ant-divider{border-top:1px solid #cbcbcb}.account-popover .edit-profile{margin-bottom:8px}.account-popover button{min-width:150px;padding:6px 8px;font-size:12px}

.roxus-sidebar{min-height:calc(100vh - 64px)}.main-content{width:100%;margin:32px 64px}

.ocr-label{font-weight:500}.predict-button{padding:0}.predicted-table .ant-table-cell{padding:8px 8px}.moderated-table .ant-table-cell{padding:8px 8px}.child-table{margin-top:20px}.child-table .ant-table-cell{padding:5px}


.edit-profile-tab .ant-card .ant-card-head-title{background:linear-gradient(to right, #ff9009 0%, #fb126b 100%);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent}.edit-profile-tab{width:100%}.edit-profile-tab .change-avatar{text-align:center}.edit-profile-tab .change-avatar .full-name{margin-top:12px}.edit-profile-tab .change-avatar .full-name span{text-transform:uppercase;font-size:16px;font-weight:500;color:#282634}.edit-profile-tab .change-avatar .ant-tag{padding:5px 10px;border-radius:5px}.edit-profile-tab .change-avatar .upload-button{margin-top:16px}.edit-profile-tab .change-avatar .ant-upload-list{display:none}.edit-profile-tab .ant-card{border-radius:20px}.edit-profile-tab .ant-card .ant-card-head-title{font-size:20px}.edit-profile-tab .ant-card .submit-button{width:100%;text-align:center}

.security-tab .ant-card .ant-card-head-title{background:linear-gradient(to right, #ff9009 0%, #fb126b 100%);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent}.security-tab{width:100%}.security-tab .ant-card{border-radius:20px}.security-tab .ant-card .ant-card-head-title{font-size:20px}.security-tab .ant-card .ant-form-item-label>label{font-size:16px}.security-tab .ant-card .submit-button{width:100%;text-align:center}

.activation-tab .ant-card .ant-card-head-title{background:linear-gradient(to right, #ff9009 0%, #fb126b 100%);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent}.activation-tab .ant-card{border-radius:20px}.activation-tab .ant-card .ant-card-head-title{font-size:20px}.activation-tab .ant-card .react-code-input input{font-size:28px !important}.activation-tab .ant-card .submit-button{width:100%;text-align:center}

.account-page .account-content{margin-bottom:16px}.account-page .account-content .account-text{font-weight:500;font-size:24px;color:#282634}

.ocr-list-page .ocr-title{margin-bottom:16px}.ocr-list-page .ocr-title .text{font-weight:500;font-size:24px;color:#282634}

.ocr-label{font-weight:500}.predict-button{padding:0}.predicted-table .ant-table-cell{padding:8px 8px}.moderated-table .ant-table-cell{padding:8px 8px}.child-table{margin-top:20px}.child-table .ant-table-cell{padding:5px}

.upload-recording-page .upload-title{margin-bottom:16px}.upload-recording-page .upload-title .text{font-weight:500;font-size:24px;color:#282634}.upload-recording-page .upload-container{margin-top:16px}

.ocr-upload-page .ocr-title{margin-bottom:16px}.ocr-upload-page .ocr-title .text{font-weight:500;font-size:24px;color:#282634}.ocr-upload-page .upload-container{margin-top:16px}

.recording-list-page .recording-title{margin-bottom:16px}.recording-list-page .recording-title .text{font-weight:500;font-size:24px;color:#282634}


.ocr-label{font-weight:500}.predict-button{padding:0}.search-area{margin-top:16px;margin-bottom:12px}.transcription-table .mark{background:#ffe58f}.transcription-table .ant-table-header table{table-layout:unset !important}.transcription-table .timecode{cursor:pointer;color:#1890ff}.transcription-table .sentiment-score .ant-col:nth-child(odd){padding-left:12px}.transcription-table .sentiment-score .ant-col:nth-child(even){text-align:right;padding-right:12px}.transcription-table .sentiment-score .positive-left{background:#f6ffed;border:1px solid #b7eb8f;border-radius:2px;border-right:none;font-weight:500;color:#389e0d}.transcription-table .sentiment-score .positive-right{background:#f6ffed;border:1px solid #b7eb8f;border-radius:2px;border-left:none;font-weight:500;color:#389e0d}.transcription-table .sentiment-score .neutral-left{background:#fafafa;border:1px solid #d9d9d9;border-radius:2px;border-right:none;font-weight:500}.transcription-table .sentiment-score .neutral-right{background:#fafafa;border:1px solid #d9d9d9;border-radius:2px;border-left:none;font-weight:500}.transcription-table .sentiment-score .negative-left{background:#fff1f0;border:1px solid #ffa39e;border-radius:2px;border-right:none;font-weight:500;color:#cf1322}.transcription-table .sentiment-score .negative-right{background:#fff1f0;border:1px solid #ffa39e;border-radius:2px;border-left:none;font-weight:500;color:#cf1322}

.search-area{margin-top:16px;margin-bottom:12px}.occasion-table .mark{background:#ffe58f}.occasion-table .bg-red{background-color:#fff1f0}.occasion-table .bg-blue{background-color:#e6f7ff}.occasion-table .bg-yellow{background-color:#fffbe6}.occasion-table .ant-table-header table{table-layout:unset !important}.occasion-table .timecode{cursor:pointer;color:#1890ff}.occasion-table .sentiment-score .ant-col:nth-child(odd){padding-left:12px}.occasion-table .sentiment-score .ant-col:nth-child(even){text-align:right;padding-right:12px}.occasion-table .sentiment-score .positive-left{background:#f6ffed;border:1px solid #b7eb8f;border-radius:2px;border-right:none;font-weight:500;color:#389e0d}.occasion-table .sentiment-score .positive-right{background:#f6ffed;border:1px solid #b7eb8f;border-radius:2px;border-left:none;font-weight:500;color:#389e0d}.occasion-table .sentiment-score .neutral-left{background:#fafafa;border:1px solid #d9d9d9;border-radius:2px;border-right:none;font-weight:500}.occasion-table .sentiment-score .neutral-right{background:#fafafa;border:1px solid #d9d9d9;border-radius:2px;border-left:none;font-weight:500}.occasion-table .sentiment-score .negative-left{background:#fff1f0;border:1px solid #ffa39e;border-radius:2px;border-right:none;font-weight:500;color:#cf1322}.occasion-table .sentiment-score .negative-right{background:#fff1f0;border:1px solid #ffa39e;border-radius:2px;border-left:none;font-weight:500;color:#cf1322}

.recording-details-page .title{margin-bottom:16px}.recording-details-page .title .text{font-weight:500;font-size:24px;color:#282634}.recording-details-page .details{margin-bottom:16px}.recording-details-page .details .label{font-weight:500;color:#003a8c}.recording-details-page .details .pipe{color:#cbcbcb}.recording-details-page .audio-player{margin-bottom:16px}.recording-details-page .audio-player audio{border:1px solid #282634;border-radius:30px}

.stream-list-page .title{margin-bottom:16px}.stream-list-page .title .text{font-weight:500;font-size:24px;color:#282634}


.stream-details-page .title{margin-bottom:16px}.stream-details-page .title .text{font-weight:500;font-size:24px;color:#282634}.stream-details-page .details{margin-bottom:16px}.stream-details-page .details .label{font-weight:500;color:#003a8c}.stream-details-page .details .pipe{color:#cbcbcb}

.tag-list-page .title{margin-bottom:16px}.tag-list-page .title .text{font-weight:500;font-size:24px;color:#282634}


.activate-page .activate-status{background:linear-gradient(to right, #ff9009 0%, #fb126b 100%);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent}.activate-page{padding:64px 64px;min-height:calc(100vh - 64px)}.activate-page .content{width:100%;height:100%}.activate-page span{font-size:16px}.activate-page .activate-status{font-size:32px;font-weight:500}

.setting-list-page .setting-title{margin-bottom:16px}.setting-list-page .setting-title .text{font-weight:500;font-size:24px;color:#282634}




.setting-list-page .setting-title{margin-bottom:16px}.setting-list-page .setting-title .text{font-weight:500;font-size:24px;color:#282634}








.edit-company-tab .ant-card .ant-card-head-title{background:linear-gradient(to right, #ff9009 0%, #fb126b 100%);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent}.edit-company-tab{width:100%}.edit-company-tab .change-avatar{text-align:center}.edit-company-tab .change-avatar .full-name{margin-top:12px}.edit-company-tab .change-avatar .full-name span{text-transform:uppercase;font-size:16px;font-weight:500;color:#282634}.edit-company-tab .change-avatar .ant-tag{padding:5px 10px;border-radius:5px}.edit-company-tab .change-avatar .upload-button{margin-top:16px}.edit-company-tab .change-avatar .ant-upload-list{display:none}.edit-company-tab .ant-card{border-radius:20px}.edit-company-tab .ant-card .ant-card-head-title{font-size:20px}.edit-company-tab .ant-card .submit-button{width:100%;text-align:center}

.edit-departments-tab .ant-card .ant-card-head-title{background:linear-gradient(to right, #ff9009 0%, #fb126b 100%);background-clip:text;-webkit-background-clip:text;-webkit-text-fill-color:transparent}.edit-departments-tab{width:100%}.edit-departments-tab .ant-card{border-radius:20px}.edit-departments-tab .ant-card .ant-card-head-title{font-size:20px}.edit-departments-tab .ant-card .submit-button{width:100%;text-align:center}




.company-details-page .company-details-content{margin-bottom:16px}.company-details-page .company-details-content .company-details-text{font-weight:500;font-size:24px;color:#282634}

.setting-list-page .setting-title{margin-bottom:16px}.setting-list-page .setting-title .text{font-weight:500;font-size:24px;color:#282634}

.ocr-label{font-weight:500}.predict-button{padding:0}.predicted-table .ant-table-cell{padding:8px 8px}.moderated-table .ant-table-cell{padding:8px 8px}.child-table{margin-top:20px}.child-table .ant-table-cell{padding:5px}





