@import "../../assets/styles/variables.scss";

.search-area {
    margin-top: $margin-md;
    margin-bottom: $margin-sm;
}

.occasion-table {
    .mark {
        background: #ffe58f;
    }

    .bg-red {
        background-color: #fff1f0;
    }

    .bg-blue {
        background-color: #e6f7ff;
    }

    .bg-yellow {
        background-color: #fffbe6;
    }

    .ant-table-header table {
        table-layout: unset !important;
    }

    .timecode {
        cursor: pointer;
        color: $blue;
    }

    .sentiment-score {
        .ant-col:nth-child(odd) {
            padding-left: 12px;
        }

        .ant-col:nth-child(even) {
            text-align: right;
            padding-right: 12px;
        }

        .positive-left {
            background: #f6ffed;
            border: 1px solid #b7eb8f;
            border-radius: 2px;
            border-right: none;
            font-weight: 500;
            color: #389e0d;
        }

        .positive-right {
            background: #f6ffed;
            border: 1px solid #b7eb8f;
            border-radius: 2px;
            border-left: none;
            font-weight: 500;
            color: #389e0d;
        }

        .neutral-left {
            background: #fafafa;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            border-right: none;
            font-weight: 500;
        }

        .neutral-right {
            background: #fafafa;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            border-left: none;
            font-weight: 500;
        }

        .negative-left {
            background: #fff1f0;
            border: 1px solid #ffa39e;
            border-radius: 2px;
            border-right: none;
            font-weight: 500;
            color: #cf1322;
        }

        .negative-right {
            background: #fff1f0;
            border: 1px solid #ffa39e;
            border-radius: 2px;
            border-left: none;
            font-weight: 500;
            color: #cf1322;
        }
    }
}
