@import "../../assets/styles/variables.scss";

.account-popover {
    .full-name {
        font-size: $font-md;
        font-weight: 500;
        color: $navy;
    }
    .title {
        font-weight: 400;
    }
    .ant-divider-horizontal {
        margin: 12px 0;
    }
    .ant-divider {
        border-top: 1px solid $grey;
    }
    .edit-profile {
        margin-bottom: 8px;
    }
    button {
        min-width: 150px;
        padding: 6px 8px;
        font-size: 12px;
    }
}
