$padding-sm: 12px;
$padding-md: 24px;
$padding-lg: 36px;
$padding-xl: 48px;

// FONTSIZE
$font-sm: 12px;
$font-md: 16px;
$font-lg: 20px;
$font-xl: 24px;
$font-xxl: 32px;

// COLOR SCHEME
$pink: #fb106c;
$orange: #ff9306;
$navy: #282634;
$blue: #65839b;
$grey: #cbcbcb;
$light-grey: rgb(244, 245, 247);
$white: #ffffff;
$red: rgb(244, 67, 54);
$blue-9: #003a8c;
$blue-10: #002766;
$blue: #1890ff;

// PADDING
$padding-sm: 12px;
$padding-md: 16px;
$padding-lg: 32px;
$padding-xl: 48px;

// MARGIN
$margin-sm: 12px;
$margin-md: 16px;
$margin-lg: 32px;
$margin-xl: 48px;

// GRADIENT
%gradient-text {
    background: linear-gradient(to right, #ff9009 0%, #fb126b 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

// BORDER RADIUS
$border-radius-sm: 10px;
$border-radius-md: 20px;
$border-radius-circle: 50%;

// COMPONENT HEIGHT
$header-height: 64px;
