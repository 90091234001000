@import "../../assets/styles/variables.scss";

.recording-details-page {
    .title {
        margin-bottom: $padding-md;
        .text {
            font-weight: 500;
            font-size: $font-xl;
            color: $navy;
        }
    }

    .details {
        margin-bottom: $padding-md;
        .label {
            font-weight: 500;
            color: $blue-9;
        }
        .pipe {
            color: $grey;
        }
    }

    .audio-player {
        margin-bottom: $padding-md;
        audio {
            border: 1px solid $navy;
            border-radius: 30px;
        }
    }
}
