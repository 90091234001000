@import "../../assets/styles/variables.scss";

.upload-recording-page {
    .upload-title {
        margin-bottom: $padding-md;
        .text {
            font-weight: 500;
            font-size: $font-xl;
            color: $navy;
        }
    }
    .upload-container {
        margin-top: 16px;
    }
}
