@import "../../assets/styles/variables.scss";

.account-profile {
    padding: 12px;

    .profile-content {
        padding: 16px 12px;
        background: $light-grey;
        border-radius: 10px;

        .avatar {
            cursor: pointer;
        }

        .full-name {
            font-size: $font-md;
            font-weight: 500;
            color: $navy;
        }
    }
}