@import '../../assets/styles/variables.scss';

.ocr-label {
    font-weight: 500;
}

.predict-button {
    padding: 0;
}

.predicted-table{
    .ant-table-cell
    {
        padding: 8px 8px;
    }
}

.moderated-table{
    .ant-table-cell
    {
        padding: 8px 8px;
    }
}

.child-table
{
    margin-top: 20px;
    .ant-table-cell
    {
        padding: 5px;
    }
}
