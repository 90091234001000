.button-gra {
    min-width: 200px;
    border: none;
    outline: none;
    display: inline-block;
    border-radius: 99px;
    color: white;
    font-weight: bold;
    position: relative;
    z-index: 1;
    padding: 13px 30px;
    text-transform: uppercase;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px;
    cursor: pointer;

    &:before,
    &:after {
        content: " ";
        position: absolute;
        border-radius: 99px;
    }

    &:before {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -2;
        background: -moz-linear-gradient(right, #fc9107 0%, #fa106c 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, #fc9107), color-stop(100%, #fa106c));
        background: -webkit-linear-gradient(right, #fc9107 0%, #fa106c 100%);
        background: -o-linear-gradient(right, #fc9107 0%, #fa106c 100%);
        background: -ms-linear-gradient(right, #fc9107 0%, #fa106c 100%);
        background: linear-gradient(to right, #fc9107 0%, #fa106c 100%);
    }

    &:after {
        top: 2px;
        bottom: 2px;
        left: 2px;
        right: 2px;
        background-color: #43464a;
        z-index: -1;
        opacity: 1;
        transition: all 0.6s ease-in-out;
    }

    &:hover:after {
        opacity: 0;
    }
}

.button-gra-inv {
    min-width: 200px;
    border: none;
    outline: none;
    display: inline-block;
    border-radius: 99px;
    color: #43464a;
    font-weight: bold;
    position: relative;
    z-index: 1;
    padding: 13px 30px;
    text-transform: uppercase;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px;
    cursor: pointer;

    &:before,
    &:after {
        content: " ";
        position: absolute;
        border-radius: 99px;
    }

    &:before {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -2;
        background: -moz-linear-gradient(right, #fc9107 0%, #fa106c 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, #fc9107), color-stop(100%, #fa106c));
        background: -webkit-linear-gradient(right, #fc9107 0%, #fa106c 100%);
        background: -o-linear-gradient(right, #fc9107 0%, #fa106c 100%);
        background: -ms-linear-gradient(right, #fc9107 0%, #fa106c 100%);
        background: linear-gradient(to right, #fc9107 0%, #fa106c 100%);
    }

    &:after {
        top: 2px;
        bottom: 2px;
        left: 2px;
        right: 2px;
        background-color: white;
        z-index: -1;
        opacity: 1;
        transition: all 0.6s ease-in-out;
    }

    &:hover:after {
        opacity: 0;
    }
}
