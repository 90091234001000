@import "../../assets/styles/variables.scss";

.ocr-upload-page {
    .ocr-title {
        margin-bottom: $padding-md;
        .text {
            font-weight: 500;
            font-size: $font-xl;
            color: $navy;
        }
    }
    .upload-container {
        margin-top: 16px;
    }
}
