@import "../../assets/styles/variables.scss";

.tag-list-page {
    .title {
        margin-bottom: $padding-md;
        .text {
            font-weight: 500;
            font-size: $font-xl;
            color: $navy;
        }
    }
}
