@import "../../assets/styles/variables.scss";

.edit-company-tab {
    width: 100%;

    .change-avatar {
        text-align: center;
        .full-name {
            margin-top: $padding-sm;
            span {
                text-transform: uppercase;
                font-size: $font-md;
                font-weight: 500;
                color: $navy;
            }
        }
        .ant-tag {
            padding: 5px 10px;
            border-radius: 5px;
        }
        .upload-button {
            margin-top: $padding-md;
        }
        .ant-upload-list {
            display: none;
        }
    }

    .ant-card {
        border-radius: $border-radius-md;
        .ant-card-head-title {
            @extend %gradient-text;
            font-size: $font-lg;
        }
        .submit-button {
            width: 100%;
            text-align: center;
        }
    }
}
