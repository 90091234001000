@import "../../assets/styles/variables.scss";

.account-page {
    .account-content {
        margin-bottom: $padding-md;
        .account-text {
            font-weight: 500;
            font-size: $font-xl;
            color: $navy;
        }
    }
}
