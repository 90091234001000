@import "../../assets/styles/variables.scss";
.ant-popover-inner {
    border-radius: 10px;
}

.ant-popover-inner-content {
    padding: 20px 30px;
}

.ant-layout-header {
    background: $navy;
    .logo {
        float: left;
        width: 120px;
        cursor: pointer;
    }
    .right-header {
        display: flex;
        float: right;
        .notification {
            margin-right: $padding-lg;

            .ant-badge-count {
                background: $pink;
                border-radius: 10px;
                box-shadow: 0 0 0 1px $pink;
            }
            .anticon-bell {
                cursor: pointer;
                font-size: 20px;
                color: white;
            }
        }
        .avatar {
            cursor: pointer;
        }
    }
}
